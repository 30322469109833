<template>
    <div class="container-fluid d-flex flex-column charts  my-3">
        <div class="row">
            <div class="col-12">
                <div class="nav nav-tabs d-flex align-items-center">
                    <label v-for="(symbol, key) in symbols" :key="`charts-symbol-${key}`" :for="'symbol'+key" :class="(symbol === selectedSymbol) ? 'nav-item nav-link active ' : 'nav-item nav-link'">
                        <input type="radio" name="charts-tab-symbol" :value="symbol" v-model="selectedSymbol" :id="'symbol'+key" style="display: none;"/>{{ symbol }}
                    </label>
                    <div v-if="symbols.length === 0" class="align-self-baseline text-muted">{{ $t('noSymbolsFound')}}</div>
                    <el-radio-group class="period-selector ms-auto" v-model="selectedPeriod" size="small" @change="handleSelectedPeriodChange">
                        <el-radio-button v-for="(period, index) in periods" :key="`period-index-${index}`" :label="period.value" >{{ period.name }}</el-radio-button>
                    </el-radio-group>
                    <el-button class="mx-2" type="primary" size="mini" @click="openNewOrderModal">{{ $t('newOrder')}}</el-button>
                    <el-dropdown
                        :hide-on-click="false">
                          <span class="el-dropdown-link">
                            <i class="el-icon-more"></i>
                          </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-checkbox-group v-model="selectedIndicators" @change="handleSelectedIndicatorsChange">
                                <el-dropdown-item v-for="(availableIndicator, index) in availableIndicators" :key="`available-indicator-${index}`">
                                    <el-checkbox :label="availableIndicator.value">{{availableIndicator.name}}</el-checkbox>
                                </el-dropdown-item>
                            </el-checkbox-group>
                            <el-divider class="mt-2 mb-3"></el-divider>
                            <el-dropdown-item class="text-center">
                                <el-button type="primary" icon="el-icon-plus" :disabled="candlesLimit <= 10" @click="decreaseCandlesCount"/>
                                <el-button type="primary" icon="el-icon-minus" :disabled="candlesLimit >= 500" @click="increaseCandlesCount"/>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="col-12">
                <highcharts class="charts-stock" :constructor-type="'stockChart'" :options="stockOptions" :updateArgs="updateArgs" :callback="stockCallback"/>
            </div>
        </div>
        <div class="row justify-content-between mt-4">
            <!-- Live / History Tables -->
            <div class="col-md-7 col-sm-12">
                <!-- Live -->
                <h5 class="mb-3">{{ $t('live') }} {{ selectedSymbol ? '('+selectedSymbol+')' : '' }}</h5>
                <div class="overflow-scroll">
                    <table v-loading="firstLiveTradesLoading" class="charts-live-table display dataTable no-footer table-hover" style="font-size: 14px; line-height: 10px; border-bottom: none">
                        <thead>
                        <tr>
                            <th style="width: 70px;">{{ $t('ticket') }}</th>
                            <th style="width: 20px;">{{ $t('type') }}</th>
                            <th style="width: 20px;">{{ $t('lots') }}</th>
                            <th style="width: 120px;">{{ $t('openTime') }}</th>
                            <th>{{ $t('openPrice') }}</th>
                            <th>{{ $t('closePrice') }}</th>
                            <th>{{ $t('deviation') }}</th>
                            <th>{{ $t('swap') }}</th>
                            <th>{{ $t('profit') }}</th>
                        </tr>
                        </thead>
                        <tbody v-if="buyPositionsLive.length > 0 || sellPositionsLive.length > 0">
                        <tr v-for="(buyPosition, index) in buyPositionsLive" :key="`buy-positions-${index}`" @click="showClosePositionModal(buyPosition)" :style="{ color: getColorForProfit(buyPosition.totalProfit), cursor: 'pointer' }">
                            <td>{{ buyPosition.shortTicket + ' (' + buyPosition.magic_number + ')' }}</td>
                            <td>{{ buyPosition.textType }}</td>
                            <td>{{ buyPosition.lots }}</td>
                            <td>{{ buyPosition.open_time.substring(0,17) }}</td>
                            <td>{{ buyPosition.open_price }}</td>
                            <td>{{ buyPosition.close_price }}</td>
                            <td>{{ buyPosition.deviation }}</td>
                            <td>{{ buyPosition.swapAndCommission.toFixed(2) }}</td>
                            <td>{{ buyPosition.totalProfit + ' ('+ (buyPosition.totalProfit / (buyPosition.lots * 100)).toFixed(2) +')' }}</td>
                        </tr>
                        <tr>
                            <th colspan="2"></th>
                            <th>{{ buyPositionsLive.map(function (position) { return parseFloat(position.lots) }).reduce(function (a, c) { return a + c; }, 0.0).toFixed(2) }}</th>
                            <th colspan="5"></th>
                            <th>{{ buyPositionsLive.map(function (position) { return parseFloat(position.totalProfit) }).reduce(function (a, c) { return a + c; }, 0.0).toFixed(2)  }}</th>
                        </tr>
                        <tr v-for="(sellPosition, index) in sellPositionsLive" :key="`sell-positions-${index}`" @click="showClosePositionModal(sellPosition)" :style="{ color: getColorForProfit(sellPosition.totalProfit), cursor: 'pointer' }">
                            <td>{{ sellPosition.shortTicket + ' (' + sellPosition.magic_number + ')' }}</td>
                            <td>{{ sellPosition.textType }}</td>
                            <td>{{ sellPosition.lots }}</td>
                            <td>{{ sellPosition.open_time.substring(0,17) }}</td>
                            <td>{{ sellPosition.open_price }}</td>
                            <td>{{ sellPosition.close_price }}</td>
                            <td>{{ sellPosition.deviation }}</td>
                            <td>{{ sellPosition.swapAndCommission.toFixed(2) }}</td>
                            <td>{{ sellPosition.totalProfit + ' ('+ (sellPosition.totalProfit / (sellPosition.lots * 100)).toFixed(2) +')' }}</td>
                        </tr>
                        <tr>
                            <th colspan="2"></th>
                            <th>{{ sellPositionsLive.map(function (obj) { return parseFloat(obj.lots) }).reduce(function (a, c) { return a + c; }, 0.0).toFixed(2) }}</th>
                            <th colspan="5"></th>
                            <th>{{ sellPositionsLive.map(function (obj) { return parseFloat(obj.totalProfit) }).reduce(function (a, c) { return a + c; }, 0.0).toFixed(2)  }}</th>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="9" class="text-muted">{{ $t('noPositionsFound')}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!-- History -->
                <h5 class="mt-3 mb-3">{{ $t('history') }} {{ selectedSymbol ? '('+selectedSymbol+')' : '' }}</h5>
                <table  v-loading="firstHistoryTradesLoading" class="display dataTable no-footer table-hover" style="font-size: 14px; line-height: 10px; border-bottom: none">
                    <thead>
                    <tr>
                        <th style="width: 70px;">{{ $t('ticket') }}</th>
                        <th style="width: 20px;">{{ $t('type') }}</th>
                        <th style="width: 20px;">{{ $t('lots') }}</th>
                        <th style="width: 120px;">{{ $t('openTime') }}</th>
                        <th>{{ $t('closeTime') }}</th>
                        <th>{{ $t('openPrice') }}</th>
                        <th>{{ $t('closePrice') }}</th>
                        <th>{{ $t('profit') }}</th>
                    </tr>
                    </thead>
                    <tbody v-if="closedPositions.length > 0">
                    <tr v-for="(closedPosition, index) in closedPositions" :key="`closed-positions-${index}`" :style="{ color: getColorForProfit(closedPosition.totalProfit) }">
                        <td>{{ closedPosition.shortTicket + ' (' + closedPosition.magic_number + ')' }}</td>
                        <td>{{ closedPosition.textType }}</td>
                        <td>{{ closedPosition.lots }}</td>
                        <td>{{ closedPosition.open_time.substring(0,17) }}</td>
                        <td>{{ closedPosition.close_time.substring(0,17) }}</td>
                        <td>{{ closedPosition.open_price }}</td>
                        <td>{{ closedPosition.close_price }}</td>
                        <td>{{ closedPosition.totalProfit + ' ('+ (closedPosition.totalProfit / (closedPosition.lots * 100)).toFixed(2) +')' }}</td>
                    </tr>
                    <tr>
                        <th colspan="7"></th>
                        <th>{{ closedPositions.map(function (obj) { return parseFloat(obj.totalProfit) }).reduce(function (a, c) { return a + c; }, 0.0).toFixed(2)  }}</th>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <td colspan="8" class="text-muted">{{ $t('noPositionsFound')}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-md-5 col-sm-12 ps-md-5">
                <!-- Trends and signals -->
                <div v-if="isTrendsAndSignalsVisible">
                    <h5 class="mb-3">{{ $t('trendsAndSignals')}}</h5>
                    <table class="me-auto dataTable no-footer" style="width: min-content; margin: 0 auto 0 0!important;">
                        <thead>
                        <tr>
                            <th class="text-left">{{ $t('indicator')}}</th>
                            <th>M1</th>
                            <th>M5</th>
                            <th>M15</th>
                            <th>H1</th>
                            <th>H4</th>
                            <th>D1</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(indicator, indicatorIndex) in trendsIndicators" :key="`trends-indicators-${indicatorIndex}`">
                            <td class="text-left" v-html="indicator.name"></td>
                            <td v-html="indicator.period_1"></td>
                            <td v-html="indicator.period_5"></td>
                            <td v-html="indicator.period_15"></td>
                            <td v-html="indicator.period_60"></td>
                            <td v-html="indicator.period_240"></td>
                            <td v-html="indicator.period_1440"></td>
                            <td v-html="indicator.total"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Accumulated profit -->
                <div v-if="isAccumulatedProfitVisible">
                    <h5 class="mt-4 mb-3">{{ $t('accumulatedProfit') }}</h5>
                    <table class="me-auto dataTable no-footer" style="width: fit-content; margin: 0 auto 0 0!important;">
                        <thead>
                        <tr>
                            <th class="text-left">{{ $t('symbol') }}</th>
                            <th>{{ $t('day') }}</th>
                            <th>{{ $t('week') }}</th>
                            <th>{{ $t('month') }}</th>
                            <th>{{ $t('month3') }}</th>
                            <th>{{ $t('month6') }}</th>
                        </tr>
                        </thead>
                        <tbody v-if="accumulatedProfitStats.length > 0">
                        <tr v-for="(row, index) in accumulatedProfitStats" :key="`accumulated-profit-${index}`">
                            <td class="text-left">{{ row.symbol }}</td>
                            <td>{{ row.day.toFixed(2) }}</td>
                            <td>{{ row.week.toFixed(2) }}</td>
                            <td>{{ row.month.toFixed(2) }}</td>
                            <td>{{ row.month3.toFixed(2) }}</td>
                            <td>{{ row.month6.toFixed(2) }}</td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                            <td colspan="5" class="text-muted">{{ $t('noData')}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Open order/position-->
        <el-dialog
            :title="$t('open')"
            :visible.sync="showModalOpen"
            width="30%">
            <el-form
                label-position="left"
                ref="newOrderForm"
                :model="newOrder"
                label-width="110px">
                <!-- Symbol -->
                <el-form-item prop="symbol" :label="$t('symbol')">
                    <el-select size="small" v-model="newOrder.symbol" :placeholder="$t('symbol')">
                        <el-option
                            v-for="(symbol, symbolIndex) in symbols"
                            :key="symbolIndex"
                            :label="symbol"
                            :value="symbol">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- Lots -->
                <el-form-item prop="lots" :label="$t('lots')">
                    <el-input-number
                        size="small"
                        v-model="newOrder.lots"
                        controls-position="right"
                        :min="0.01"
                        :max="10"
                        :step="0.01"
                        :label="$t('lots')"/>
                </el-form-item>
                <!-- Type -->
                <el-form-item prop="selectedType" :label="$t('type')">
                    <el-select size="small" v-model="newOrder.selectedType" :placeholder="$t('type')" @change="newOrder.type = (newOrder.selectedType === 'market') ? 0 : 2">
                        <el-option label="Market Execution" value="market"/>
                        <el-option label="Pending Order" value="pending"/>
                    </el-select>
                </el-form-item>
                <!-- Order type -->
                <el-form-item prop="type" :label="$t('orderType')">
                    <el-select size="small" v-model="newOrder.type" :placeholder="$t('orderType')">
                        <el-option v-if="newOrder.selectedType === 'market'" :value="0" label="Buy"/>
                        <el-option v-if="newOrder.selectedType === 'market'" :value="1" label="Sell"/>
                        <el-option v-if="newOrder.selectedType === 'pending'" :value="2" label="Buy Limit"/>
                        <el-option v-if="newOrder.selectedType === 'pending'" :value="3" label="Sell Limit"/>
                        <el-option v-if="newOrder.selectedType === 'pending'" :value="4" label="Buy Stop"/>
                        <el-option v-if="newOrder.selectedType === 'pending'" :value="5" label="Sell Stop"/>
                    </el-select>
                </el-form-item>
                <!-- Stop loss -->
                <el-form-item prop="stopLoss" label="Stop loss" v-show="newOrder.selectedType === 'market'">
                    <el-input-number
                        size="small"
                        v-model="newOrder.stopLoss"
                        controls-position="right"
                        :min="0"
                        :max="100000"
                        :step="10"
                        label="Stop loss"/>
                </el-form-item>
                <!-- Take profit -->
                <el-form-item prop="takeProfit" label="Take profit" v-show="newOrder.selectedType === 'market'">
                    <el-input-number
                        size="small"
                        v-model="newOrder.takeProfit"
                        controls-position="right"
                        :min="0"
                        :max="100000"
                        :step="10"
                        label="Take profit"/>
                </el-form-item>
                <!-- Deviation -->
                <el-form-item prop="deviation" :label="$t('deviation')" v-show="newOrder.selectedType === 'pending'">
                    <el-input-number
                        size="small"
                        v-model="newOrder.deviation"
                        controls-position="right"
                        :min="0"
                        :max="100000"
                        :step="10"
                        label="Deviation"/>
                </el-form-item>
                <!-- Magic -->
                <el-form-item prop="magic" label="Magic">
                    <el-select size="small" v-model="newOrder.magic" placeholder="Magic" @change="newOrderMagicChange">
                        <el-option :value="6" label="Martin 6"/>
                        <el-option :value="7" label="Martin 7"/>
                        <el-option :value="8" label="Fishing"/>
                        <el-option :value="9" label="PL"/>
                        <el-option :value="0" label="Manual"/>
                    </el-select>
                </el-form-item>
                <!-- Comment -->
                <el-form-item prop="comment" :label="$t('comment')">
                    <el-input size="small" v-model="newOrder.comment" :placeholder="$t('comment')"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="showModalOpen = false">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="newOrderClick">{{ $t('open') }}</el-button>
                </span>
        </el-dialog>
        <!-- Close position/ Delete order -->
        <el-dialog
            :title="(selectedTradeForClose.type > 1) ? $t('deletePosition') : $t('closePosition')"
            :visible.sync="showModalClose"
            width="30%">
            <el-form
                ref="closePositionForm"
                :model="selectedTradeForClose"
                label-position="left"
                label-width="80px">
                <el-form-item prop="ticket" :label="$t('ticket')">
                    <p class="m-0" style="font-size: 16px;">{{ selectedTradeForClose.ticket }}</p>
                </el-form-item>
                <el-form-item prop="symbol" :label="$t('symbol')">
                    <p class="m-0" style="font-size: 16px;">{{ selectedTradeForClose.symbol }}</p>
                </el-form-item>
                <el-form-item prop="type" :label="$t('type')">
                    <p class="m-0" style="font-size: 16px;">{{ tradeTypeToName(selectedTradeForClose.type) }}</p>
                </el-form-item>
                <el-form-item prop="profit" :label="$t('profit')">
                    <p class="m-0" style="font-size: 16px;">{{ (selectedTradeForClose.profit+selectedTradeForClose.swap+selectedTradeForClose.commission).toFixed(2) }}</p>
                </el-form-item>
                <el-form-item prop="lots" :label="$t('lots')">
                    <el-input-number
                        size="small"
                        controls-position="right"
                        v-if="selectedTradeForClose.type <= 1"
                        v-model="selectedTradeForClose.lots"
                        :min="0.00"
                        :step="0.01"
                        :max="selectedTradeForClose.origLots"
                        label="label"/>
                    <div v-else>
                        {{ selectedTradeForClose.lots }}
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModalClose = false">{{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="closePositionClick">{{ (selectedTradeForClose.type > 1) ? $t('delete') : $t('close') }}</el-button>
              </span>
        </el-dialog>
        <!-- Open pending orders after close? -->
        <el-dialog
            :title="$t('openLimitAndStopOrders')"
            :visible.sync="showModalOpenAfterClose"
            width="30%">
                <span slot="footer" class="dialog-footer">
                <el-button @click="showModalOpenAfterClose = false">{{ $t('cancel') }}</el-button>
                <el-button type="primary" @click="openPendingOrder(false)">{{ $t('no') }}</el-button>
                <el-button type="primary" @click="openPendingOrder(true)">{{ $t('yes') }}</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from '@/plugins/axios'
import {dateTimeToString, tradeTypeToName} from '@/common/helpers'

const CANDLES_LIMIT_NAME = 'admin-charts-candles-limit'
const SELECTED_PERIOD = 'admin-charts-period'
const SELECTED_INDICATORS = 'admin-charts-indicators'

export default {
    name: 'Charts',
    i18n: {
        messages: {
            en: {
                noSymbolsFound: 'No symbols found',
                newOrder: 'New order',
                live: 'Live',
                history: 'History',
                indicators: 'Indicators',
                trendsAndSignals: 'Trends & signals',
                trends: 'Trends',
                signals: 'Signals',
                indicator: 'Indicator',
                accumulatedProfit: 'Accumulated profit',

                day: 'Day',
                week: 'Week',
                month: 'Month',
                month3: '3 months',
                month6: '6 months',
                noData: 'No data',

                symbol: 'Symbol',
                ticket: 'Ticket',
                type: 'Type',
                lots: 'Lots',
                openTime: 'Open Time',
                closeTime: 'Close Time',
                openPrice: 'Open Price',
                closePrice: 'Close Price',
                deviation: 'Deviation',
                swap: 'Swap',
                profit: 'Profit',
                comment: 'Comment',
                noPositionsFound: 'No positions found',

                orderType: 'Order type',
                cancel: 'Cancel',
                delete: 'Delete',
                close: 'Close',
                open: 'Open',
                deletePosition: 'Delete position',
                closePosition: 'Close position',
                yes: 'Yes',
                no: 'No',
                openLimitAndStopOrders: 'Open limit and stop orders?',
            },
            ru: {
                noSymbolsFound: 'Пары не найдены',
                newOrder: 'Новый ордер',
                live: 'Открытые',
                history: 'Закрытые',
                indicators: 'Индикаторы',
                trendsAndSignals: 'Тренды и сигналы',
                trends: 'Тренды',
                signals: 'Сигналы',
                indicator: 'Индикатор',
                accumulatedProfit: 'Накопленная прибыль',

                day: 'День',
                week: 'Неделя',
                month: 'Месяц',
                month3: '3 месяца',
                month6: '6 месяцев',
                noData: 'Нет данных',

                symbol: 'Пара',
                ticket: 'Тикет',
                type: 'Тип',
                lots: 'Лоты',
                openTime: 'Время открытия',
                closeTime: 'Время закрытия',
                openPrice: 'Цена открытия',
                closePrice: 'Цена закрытия',
                deviation: 'Отклонение',
                swap: 'Своп',
                profit: 'Прибыль',
                comment: 'Комментарий',
                noPositionsFound: 'Позиции не найдены',

                orderType: 'Тип ордера',
                cancel: 'Отмена',
                delete: 'Удалить',
                close: 'Закрыть',
                open: 'Открыть',
                deletePosition: 'Удалить ордер',
                closePosition: 'Закрыть позицию',
                yes: 'Да',
                no: 'Нет',
                openLimitAndStopOrders: 'Открыть лимитные и стоповые ордера?',
            }
        }
    },
    components: {},
    data: function () {
        return {
            accumulatedProfitStats: [],
            getSymbolsTimer: null,
            selectedSymbol: null,
            //Период
            periods: [
                {
                    name: 'M1',
                    value: 1
                },
                {
                    name: 'M5',
                    value: 5
                },
                {
                    name: 'M15',
                    value: 15
                },
                {
                    name: 'H1',
                    value: 60
                },
                {
                    name: 'H4',
                    value: 240
                },
                {
                    name: 'D1',
                    value: 1440
                }],
            //График
            getLastCandleTimer: null,
            stockOptions: {
                credits: {
                    enabled: false
                },
                chart: {
                    height: 450,
                    type: 'candlestick',
                    events: {
                        click: function (event) {
                            const clickedElementClassName = event.target.className
                            if (typeof clickedElementClassName === 'string' && clickedElementClassName.includes('chart_label')) {
                                const trade = {
                                    account_id: parseInt(event.target.getAttribute('data-account_id')),
                                    ticket: parseInt(event.target.getAttribute('data-ticket')),
                                    lots: parseFloat(event.target.getAttribute('data-lots')),
                                    symbol: event.target.getAttribute('data-symbol'),
                                    type: parseInt(event.target.getAttribute('data-type')),
                                    profit: parseFloat(event.target.getAttribute('data-profit')),
                                    swap: parseFloat(event.target.getAttribute('data-swap')),
                                    commission: parseFloat(event.target.getAttribute('data-commission')),
                                }
                                this.showClosePositionModal(trade)
                            }
                        }.bind(this)
                    }
                },
                tooltip: {
                    enabled: false
                },
                //скрыть нижнюю панель (навигатор)
                navigator: {
                    enabled: false
                },
                //и скроллбар
                scrollbar: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        dataGrouping: {
                            enabled: false
                        }
                    },
                    psar: {
                        color: '#acacac',
                        marker: {
                            enabled: true,
                            symbol: 'circle',
                            radius: 2,
                            fillColor: '#acacac'
                        },
                    }
                },
                yAxis: {
                    offset: 10, //offset: 50,
                    endOnTick: false,
                    startOnTick: false,
                    scalable: true, // масштабирование оси y
                    currentPriceIndicator: {
                        backgroundColor: '#000000',
                        priceIncreaseColor: 'black',
                        priceDecreaseColor: 'black',
                        borderColor: '#000000',
                        lineColor: '#000000',
                        lineDashStyle: 'Solid',
                        pullLeft: 15, //15
                        lineOpacity: 0.8,
                        triangle: {width: 10},
                        enabled: true,
                        style: {
                            color: '#ffffff',
                            fontSize: '11px'
                        },
                        x: 0,
                        y: 0,
                        zIndex: 7,
                        boxIncreaseWidthBy: 10
                    },
                    labels: {
                        align: 'left'
                    },
                    plotLines: [],
                },
                rangeSelector: {
                    enabled: false,
                    buttons: [{
                        type: 'hour',
                        count: 1,
                        text: '1h'
                    }, {
                        type: 'day',
                        count: 1,
                        text: '1D'
                    }, {
                        type: 'all',
                        count: 1,
                        text: 'All'
                    }],
                    selected: 9,
                    inputEnabled: false
                },
                series: []
            },
            updateArgs: [true, true, {duration: 1000}],
            stockChart: null,
            // Массивы позиций для вывода в таблице
            buyPositionsLive: [],
            sellPositionsLive: [],

            closedPositions: [],
            showModalClose: false,
            showModalOpen: false,
            showModalOpenAfterClose: false,

            selectedTradeForClose: {
                ticket: null,
                symbol: null,
                type: null,
            },
            newOrder: {
                symbol: '',
                lots: 0.01,
                selectedType: 'market', //pending
                type: 0,
                magic: 7,
                comment: '(7)',
                deviation: 200,
                stopLoss: 0,
                takeProfit: 0,
            },
            clientIndicators: [],
            clientIndicatorsParams: null,

            availableIndicators: [
                {
                    name: 'Ichimoku',
                    value: 'ikh'
                },
                {
                    name: 'Bollinger Bands',
                    value: 'bb'
                },
                {
                    name: 'Parabolic SAR',
                    value: 'psar'
                },
                {
                    name: 'Fractals',
                    value: 'fractals'
                },
            ],
            firstLiveTradesLoading: true,
            firstHistoryTradesLoading: true,
        }
    },
    computed: {
        selectedAccount: function () {
            return this.$store.getters.ADMIN_SELECTED_ACCOUNT
        },
        symbols: function () {
            return this.$store.getters['admin/accountSymbols']
        },
        trendsIndicators: function () {
            let periods = [
                'period_1',
                'period_5',
                'period_15',
                'period_60',
                'period_240',
                'period_1440',
                'total'
            ]
            let indicators = this.clientIndicators.filter(indicator => indicator.type === 'trend')

            const indicatorsOrder = ['Bollinger (Up)', 'Bollinger (Lo)', 'Ichimoku', 'MACD', 'ADX']
            indicators.sort((a, b) => indicatorsOrder.indexOf(a.name) - indicatorsOrder.indexOf(b.name))

            for(let indicatorIndex = 0; indicatorIndex < indicators.length; indicatorIndex++) {
                periods.forEach(period => {
                    let colorClass = ''
                    if(indicators[indicatorIndex][period] < 0) {
                        colorClass = 'color-red'
                    } else if(indicators[indicatorIndex][period] > 0) {
                        colorClass = 'color-blue'
                    }

                    // Round indicator value
                    if(indicators[indicatorIndex].name.includes('Bollinger')) {
                        if(indicators[indicatorIndex].symbol.includes('JPY')) {
                            indicators[indicatorIndex][period] = indicators[indicatorIndex][period].toFixed(3)
                        } else {
                            indicators[indicatorIndex][period] = indicators[indicatorIndex][period].toFixed(5)
                        }
                    } else {
                        indicators[indicatorIndex][period] = indicators[indicatorIndex][period].toFixed(2)
                    }

                    indicators[indicatorIndex][period] = `<span class="${colorClass}">${indicators[indicatorIndex][period]}</span>`
                })

                if(indicators[indicatorIndex].name === 'Ichimoku') {
                    indicators[indicatorIndex].period_1 = ''
                    indicators[indicatorIndex].period_5 = ''
                    indicators[indicatorIndex].period_15 = ''
                }

                if(indicators[indicatorIndex].name.includes('Bollinger')) {
                    indicators[indicatorIndex].total = ''
                }
            }
            return indicators
        },
        signalsIndicators: function () {
            return this.clientIndicators.filter(indicator => indicator.type === 'signal')
        },
        isTrendsAndSignalsVisible: function () {
            return this.$store.getters['admin/chartsSettings/isTrendsAndSignalsVisible']
        },
        isAccumulatedProfitVisible: function () {
            return this.$store.getters['admin/chartsSettings/isAccumulatedProfitVisible']
        },
        candlesLimit: {
            get: function () {
                return this.$store.getters['admin/chartsSettings/candlesLimit']
            },
            set: function (value) {
                this.$store.commit('admin/chartsSettings/setCandlesLimit', value)
                this.$store.dispatch('admin/chartsSettings/saveState')
            }
        },
        selectedPeriod: {
            get: function () {
                return this.$store.getters['admin/chartsSettings/selectedPeriod']
            },
            set: function (value) {
                this.$store.commit('admin/chartsSettings/setSelectedPeriod', value)
                this.$store.dispatch('admin/chartsSettings/saveState')
            }
        },
        selectedIndicators: {
            get: function () {
                return this.$store.getters['admin/chartsSettings/selectedIndicators']
            },
            set: function (value) {
                this.$store.commit('admin/chartsSettings/setSelectedIndicators', value)
                this.$store.dispatch('admin/chartsSettings/saveState')
            }
        },
    },
    watch: {
        selectedSymbol: function () {
            this.firstLiveTradesLoading = true
            this.firstHistoryTradesLoading = true
            this.buyPositionsLive = []
            this.sellPositionsLive = []
            this.closedPositions = []
            this.reloadQuotes()
            this.reloadClientIndicators()
            this.reloadAccumulatedProfit()
        },
        closedPositions: function (newValue, oldValue) {
            if(newValue.length !== oldValue.length) {
                this.reloadAccumulatedProfit()
            }
        }
    },
    methods: {
        dateTimeToString,
        tradeTypeToName,
        stockCallback: function (chart) {
            this.stockChart = chart
        },
        increaseCandlesCount: function () {
            this.candlesLimit += 10
            this.reloadQuotes()
        },
        decreaseCandlesCount: function () {
            this.candlesLimit -= 10
            this.reloadQuotes()
        },
        reloadQuotes: function () {
            clearInterval(this.getLastCandleTimer)
            // TODO: refactor
            if(!this.selectedSymbol) {
                return
            }
            this.stockChart.showLoading()
            this.stockOptions.series = []

            this.$store.dispatch('admin/quotesGetRequest', {
                symbol: this.selectedSymbol,
                period: this.selectedPeriod,
                limit: this.candlesLimit
            })
                .then(async response => {
                    let quotes = []
                    response.data.forEach(quote => {
                        quotes.push([
                            quote.datetime, //дата (unix timestamp in milliseconds)
                            quote.open,     //open
                            quote.high,     //high
                            quote.low,      //low
                            quote.close,    //close
                            quote.volume,   //volume
                        ])
                    })

                    this.getLiveTrades()
                    this.getHistoryTrades()
                    this.reloadClientIndicators()
                    try {
                        const resp = await this.reloadClientIndicatorsParams()
                        this.clientIndicatorsParams = resp['data']
                    } catch (e) {
                        this.clientIndicatorsParams = null
                    }

                    // Check if we have some quotes
                    if(!quotes.length) {
                        this.$notify({
                            type: 'info',
                            title: 'No quotes',
                            message: 'No quotes found for this symbol'
                        })
                        return
                    }

                    let seriesToAdd = [
                        {
                            id: this.selectedSymbol,
                            name: this.selectedSymbol,
                            type: 'candlestick',
                            data: quotes,
                            // tooltip: {
                            //     valueDecimals: valueDecimals
                            // },
                            color: '#7cb5ec',
                            visible: true,
                        },
                        {
                            id: 'ikh',
                            type: 'ikh',
                            linkedTo: this.selectedSymbol,
                            tenkanLine: {
                                styles: {
                                    lineColor: '#ff0000'
                                }
                            },
                            kijunLine: {
                                styles: {
                                    lineColor: '#00a0ff'
                                }
                            },
                            chikouLine: {
                                styles: {
                                    lineColor: 'green',
                                    lineWidth: 1,
                                }
                            },
                            senkouSpanA: {
                                styles: {
                                    lineColor: 'green'
                                }
                            },
                            senkouSpanB: {
                                styles: {
                                    lineColor: 'red'
                                }
                            },
                            senkouSpan: {
                                styles: {
                                    overColor: 'rgba(149, 210, 129,0.3)',
                                    underColor: 'rgba(231, 120, 77, 0.3)'
                                }
                            },
                            visible: false,
                            params: {
                                period: 26, // kijun_sen
                                periodSenkouSpanB: 52, // senkou_span_b
                                periodTenkan: 9 // tenkan_sen
                            }
                        },
                        {
                            // Bollinger bands (BB)
                            id: 'bb',
                            type: 'bb',
                            linkedTo: this.selectedSymbol,
                            // толщина средней линии
                            lineWidth: 1,
                            visible: false,
                        },
                        {
                            // Parabolic SAR
                            id: 'psar',
                            type: 'psar',
                            linkedTo: this.selectedSymbol,
                            visible: false,
                        },
                        {
                            // Fractals
                            id: 'fractals',
                            type: 'fractals',
                            linkedTo: this.selectedSymbol,
                            visible: false,
                        }
                    ]

                    if(this.clientIndicatorsParams) {
                        seriesToAdd[1].params.period = this.clientIndicatorsParams.kijun_sen
                        seriesToAdd[1].params.periodSenkouSpanB = this.clientIndicatorsParams.senkou_span_b
                        seriesToAdd[1].params.periodTenkan = this.clientIndicatorsParams.tenkan_sen

                        this.availableIndicators[0].name = `Ichimoku (${seriesToAdd[1].params.periodTenkan}, ${seriesToAdd[1].params.period}, ${seriesToAdd[1].params.periodSenkouSpanB})`
                    } else {
                        this.availableIndicators[0].name = `Ichimoku`
                    }

                    // Check indicators that must be visible
                    seriesToAdd.forEach((series, index) => {
                        // у цены нет типа
                        if (seriesToAdd[index].type === 'candlestick') {
                            return
                        }
                        seriesToAdd[index].visible = this.selectedIndicators.includes(series.id)
                    })

                    // Add to chart, render only on last one
                    for(let i = 0; i < seriesToAdd.length; i++) {
                        this.stockChart.addSeries(seriesToAdd[i], i === seriesToAdd.length - 1)
                    }

                    // Set extremes
                    this.stockChart.yAxis.forEach((yAxis, index) => {
                        let extremes = yAxis.getExtremes(),
                            dataMin = extremes.dataMin,
                            dataMax = extremes.dataMax
                        this.stockChart.yAxis[index].setExtremes(dataMin, dataMax, true, false)
                    })

                    //Сброс масштабирования по оси y Highcharts.charts[1].series[0]
                    // let extremes = Highcharts.charts[1].yAxis[0].getExtremes(),
                    //     dataMin = extremes.dataMin,
                    //     dataMax = extremes.dataMax;
                    // Highcharts.charts[1].yAxis[0].setExtremes(dataMin, dataMax, true, false);
                    //установка таймера для обновления последней свечи

                    this.getLastCandleTimer = setInterval(() => {
                        this.getLastCandle()
                        this.getLiveTrades()
                        this.getHistoryTrades()
                        this.reloadClientIndicators()
                    }, 2000)
                })
                .finally(() => {
                    this.stockChart.hideLoading()
                })
        },
        getLastCandle: function () {
            // Find index of series with candles
            const symbolSeriesIndex = this.stockChart.series.findIndex(series => series.type === 'candlestick')
            if(symbolSeriesIndex === -1) {
                return
            }

            // Get latest candle
            this.$store.dispatch('admin/quotesGetRequest', {
                symbol: this.selectedSymbol,
                period: this.selectedPeriod,
                limit: 1
            })
                .then(response => {
                    if (!response.data.length) {
                        return
                    }

                    const lastPointInResponse = response.data[0]

                    const lastCandleIndexInChart = this.stockChart.series[symbolSeriesIndex].data.length - 1
                    if(lastCandleIndexInChart < 0) {
                        return
                    }

                    if(this.stockChart.series[symbolSeriesIndex].data[lastCandleIndexInChart].x === lastPointInResponse['datetime']) {
                        this.stockChart.series[symbolSeriesIndex].data[lastCandleIndexInChart].update({
                            x: lastPointInResponse['datetime'],
                            open: lastPointInResponse['open'],
                            high: lastPointInResponse['high'],
                            low: lastPointInResponse['low'],
                            close: lastPointInResponse['close'],
                        })
                    } else {
                        this.stockChart.series[symbolSeriesIndex].addPoint({
                            x: lastPointInResponse['datetime'],
                            open: lastPointInResponse['open'],
                            high: lastPointInResponse['high'],
                            low: lastPointInResponse['low'],
                            close: lastPointInResponse['close'],
                        })
                    }
                })
        },
        getLiveTrades: function () {
            this.$store.dispatch('admin/tradesGetRequest', {
                account_ids: this.selectedAccount.account_id,
                symbols: this.selectedSymbol,
                results: 0,
            })
                .then(response => {
                    const trades = response.data

                    this.buyPositionsLive = []
                    this.sellPositionsLive = []
                    let buyPositionsAveragePrice = 0
                    let sellPositionsAveragePrice = 0

                    // Добавления недостающих свойств и разделение на массивы buy/sell
                    let sumLotsOnOpenPriceBuy = 0
                    let sumLotsBuy = 0
                    let sumLotsOnOpenPriceSell = 0
                    let sumLotsSell = 0
                    for (let i = 0; i < trades.length; i++) {
                        trades[i].shortTicket = trades[i].ticket.toString().substr(-4)
                        trades[i].textType = tradeTypeToName(trades[i].type)
                        trades[i].open_time = dateTimeToString(trades[i].open_time)
                        trades[i].totalProfit = (trades[i].profit + trades[i].swap + trades[i].commission).toFixed(2)
                        trades[i].lots = trades[i].lots.toFixed(2)
                        //trades[i].expert = magicToName(trades[i].magic_number);
                        trades[i].swapAndCommission = trades[i].swap + trades[i].commission
                        // Количество знаков после запятой в цене
                        let digits = trades[i].symbol.indexOf('JPY') === -1 ? 5 : 3
                        // Если тип Buy
                        if (trades[i].type % 2 === 0) {
                            sumLotsBuy += +trades[i].lots
                            sumLotsOnOpenPriceBuy += +trades[i].lots * +trades[i].open_price
                            trades[i]['deviation'] = ((trades[i].close_price - trades[i].open_price) * Math.pow(10, digits)).toFixed(0)
                            this.buyPositionsLive.push(trades[i])
                        } else { // если Sell
                            sumLotsSell += +trades[i].lots
                            sumLotsOnOpenPriceSell += +trades[i].lots * +trades[i].open_price
                            trades[i]['deviation'] = ((trades[i].open_price - trades[i].close_price) * Math.pow(10, digits)).toFixed(0)
                            this.sellPositionsLive.push(trades[i])
                        }
                    }

                    // Расчет средних цен для buy/sell
                    if (sumLotsBuy !== 0) buyPositionsAveragePrice = sumLotsOnOpenPriceBuy / sumLotsBuy
                    if (sumLotsSell !== 0) sellPositionsAveragePrice = sumLotsOnOpenPriceSell / sumLotsSell

                    // Сортировка по типу: все позиции выше отложенных и по deviation: плохие выше
                    this.buyPositionsLive.sort(function (a, b) {
                        if (a.type === b.type) {
                            return a.deviation - b.deviation
                        }
                        return a.type > b.type ? 1 : -1
                    })
                    this.sellPositionsLive.sort(function (a, b) {
                        if (a.type === b.type) {
                            return a.deviation - b.deviation
                        }
                        return a.type > b.type ? 1 : -1
                    })

                    // Обновление графика
                    // Удаление линий (кроме линии цены)
                    let count = 0
                    while (this.stockChart.yAxis[0].plotLinesAndBands.length > count) {
                        if (this.stockChart.yAxis[0].plotLinesAndBands[count].options.id === 'close-price-line') {
                            count += 1
                            continue
                        }
                        this.stockChart.yAxis[0].removePlotLine(this.stockChart.yAxis[0].plotLinesAndBands[count].options.id)
                    }

                    // Добавление линий открытых позиций
                    for (let i = 0; i < trades.length; i++) {
                        this.stockChart.yAxis[0].addPlotLine({
                            value: trades[i].open_price,
                            color: (trades[i].type % 2 === 0) ? '#00a0ff' : '#FF0000',
                            width: 1,
                            zIndex: 200,
                            label: {
                                useHTML: true,
                                text: '<div class="chart_label pr-2"  data-ticket="' + trades[i].ticket + '" data-symbol="' + trades[i].symbol + '" data-type="' + trades[i].type + '" data-profit="' + trades[i].profit + '" data-swap="' + trades[i].swap + '" data-commission="' + trades[i].commission + '" data-lots="' + trades[i].lots + '" data-account_id="' + trades[i].account_id + '">' + trades[i].ticket.toString().substr(-4) + ' ' + tradeTypeToName(trades[i].type) + ' ' + trades[i].lots + ' ' + (trades[i].profit + trades[i].swap + trades[i].commission).toFixed(2) + ' ' + trades[i].magic_number + '</div>',
                                y: 3,
                                x: 0,
                            },
                            id: 'position-line-' + i,
                            dashStyle: (trades[i].type <= 1) ? 'Solid' : 'LongDash',
                        })
                    }

                    // Добавление линий средних цен на график
                    if (buyPositionsAveragePrice !== 0) {
                        this.stockChart.yAxis[0].addPlotLine({
                            value: buyPositionsAveragePrice,
                            color: '#00a0ff',
                            width: 3,
                            zIndex: 200,
                            id: 'average-buy-price',
                            dashStyle: 'Solid',
                        })
                    }
                    if (buyPositionsAveragePrice !== 0) {
                        this.stockChart.yAxis[0].addPlotLine({
                            value: sellPositionsAveragePrice,
                            color: '#ff0000',
                            width: 3,
                            zIndex: 200,
                            id: 'average-sell-price',
                            dashStyle: 'Solid',
                        })
                    }
                })
                .catch(() => {
                    this.buyPositionsLive = []
                    this.sellPositionsLive = []
                })
                .finally(() => {
                    this.firstLiveTradesLoading = false
                })
        },
        getHistoryTrades: function () {
            let startDateTime = new Date()
            startDateTime.setUTCHours(0, 0, 0, 0)
            // for testing: startDateTime.setDate(startDateTime.getDate() - 50)
            startDateTime = startDateTime.toJSON()

            this.$store.dispatch('admin/tradesGetRequest', {
                account_ids: this.selectedAccount.account_id,
                symbols: this.selectedSymbol,
                results: 1,
                start_close_time: startDateTime,
            })
                .then(response => {
                    let closedTrades = response.data
                    for (let i = 0; i < closedTrades.length; i++) {
                        closedTrades[i].shortTicket = closedTrades[i].ticket.toString().substr(-4)
                        closedTrades[i].textType = tradeTypeToName(closedTrades[i].type)
                        closedTrades[i].open_time = dateTimeToString(closedTrades[i].open_time)
                        closedTrades[i].close_time = dateTimeToString(closedTrades[i].close_time)
                        closedTrades[i].totalProfit = (closedTrades[i].profit + closedTrades[i].swap + closedTrades[i].commission).toFixed(2)
                        closedTrades[i].lots = closedTrades[i].lots.toFixed(2)
                        closedTrades[i].swapAndCommission = closedTrades[i].swap + closedTrades[i].commission
                        // Количество знаков после запятой в цене
                        let digits = closedTrades[i].symbol.indexOf('JPY') === -1 ? 5 : 3
                        // Если тип Buy
                        if (closedTrades[i].type % 2 === 0) {
                            closedTrades[i]['deviation'] = ((closedTrades[i].close_price - closedTrades[i].open_price) * Math.pow(10, digits)).toFixed(0)
                        } else { // если Sell
                            closedTrades[i]['deviation'] = ((closedTrades[i].open_price - closedTrades[i].close_price) * Math.pow(10, digits)).toFixed(0)
                        }
                    }

                    this.closedPositions = closedTrades
                })
                .catch(() => {
                    this.closedPositions = []
                })
                .finally(() => {
                    this.firstHistoryTradesLoading = false
                })
        },
        reloadClientIndicators: function () {
            if(!this.isTrendsAndSignalsVisible) {
                return
            }

            axios.get('/admin/client-indicators', {
                params: {
                    account_id: this.selectedAccount.account_id,
                    symbol: this.selectedSymbol
                }
            })
                .then(response => {
                    this.clientIndicators = response.data
                })
                .catch(() => {
                    this.clientIndicators = []
                })
        },
        reloadClientIndicatorsParams: async function () {
            return await axios.get('/admin/client-indicators-params', {
                params: {
                    account_id: this.selectedAccount.account_id,
                    symbol: this.selectedSymbol,
                    type: 'ikh',
                    period: this.selectedPeriod,
                }
            })
        },
        reloadSymbols: function () {
            this.$store.dispatch('admin/accountSymbolsGetRequest', [this.selectedAccount.account_id])
                .then(() => {
                    if (!this.selectedSymbol) {
                        this.selectedSymbol = this.symbols[0]
                    }
                })
        },
        reloadAccumulatedProfit: function () {
            this.$store.dispatch('admin/tradesAccumulatedProfitGetRequest', {
                account_ids: this.selectedAccount.account_id,
                symbols: this.selectedSymbol,
            })
            .then(response => {
                this.accumulatedProfitStats = response.data
            })
            .catch(() => {
                this.accumulatedProfitStats = []
            })
        },
        getColorForProfit: function (profit) {
            if (profit > 0)
                return 'blue'
            if (profit < 0)
                return 'red'
            return 'black'
        },
        openNewOrderModal: function () {
            this.newOrder.symbol = this.selectedSymbol
            this.showModalOpen = true
        },
        handleSelectedPeriodChange: function () {
            this.stockOptions.series = []
            this.reloadQuotes()
        },
        handleSelectedIndicatorsChange: function () {
            // Check visible indicators
            this.stockChart.series.forEach((series, index) => {
                // If series doesn't have type
                if (this.stockChart.series[index].type === 'candlestick') {
                    return
                }
                this.stockChart.series[index].setVisible(this.selectedIndicators.includes(series.options.id), false) // visible, redraw
            })
            this.stockChart.redraw()
            this.getLiveTrades()
        },
        showClosePositionModal: function (trade) {
            this.selectedTradeForClose = trade
            this.selectedTradeForClose.origLots = parseFloat(trade.lots)
            this.showModalClose = true
        },
        closePositionClick: function () {
            if (this.selectedTradeForClose.type <= 1) {
                this.showModalOpenAfterClose = true
            } else {
                // Close
                axios.post('/admin/web-orders', {
                    account_id: this.selectedAccount.account_id,
                    ticket: this.selectedTradeForClose.ticket,
                    lot: this.selectedTradeForClose.lots,
                    symbol: this.selectedTradeForClose.symbol,
                    type: 'close'
                })
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Ok',
                            message: 'The order has been sent'
                        })
                    })
                    .catch(() => {
                        this.$notify({
                            type: 'error',
                            title: 'Error',
                            message: 'Error while order sending'
                        })
                    })
                    .finally(() => {
                        this.showModalOpenAfterClose = false
                        this.showModalClose = false
                        this.selectedTradeForClose = {
                            ticket: null,
                            symbol: null,
                            type: null,
                        }
                    })
            }
        },
        openPendingOrder: function (needToOpenPendingOrders) {
            let requests = [
                axios.post('/admin/web-orders', {
                    account_id: this.selectedAccount.account_id,
                    ticket: this.selectedTradeForClose.ticket,
                    lot: this.selectedTradeForClose.lots,
                    symbol: this.selectedTradeForClose.symbol,
                    type: 'close'
                })
            ]

            if (needToOpenPendingOrders) { // Close and open pending order
                console.log("Close and open pending orders.")
                let typesForOpen = []
                switch (this.selectedTradeForClose.type) {
                    // Buy
                    case 0:
                        // Buy limit, Buy stop
                        typesForOpen.push(2, 4)
                        break
                    // Sell
                    case 1:
                        // Sell limit, sell stop
                        typesForOpen.push(3, 5)
                        break
                    default:
                        alert("Error. Contact support.")
                        return
                }
                // Close and open pending order.
                requests.push(
                    axios.post('/admin/web-orders', {
                        account_id: this.selectedAccount.account_id,
                        symbol: this.selectedTradeForClose.symbol,
                        lot: this.selectedTradeForClose.lots,
                        type_open: typesForOpen[0],
                        deviation: 100,
                        magic: this.selectedTradeForClose.magic_number,
                        comment: null,
                        type: 'open'
                    }),
                    axios.post('/admin/web-orders', {
                        account_id: this.selectedAccount.account_id,
                        symbol: this.selectedTradeForClose.symbol,
                        lot: this.selectedTradeForClose.lots,
                        type_open: typesForOpen[1],
                        deviation: 100,
                        magic: this.selectedTradeForClose.magic_number,
                        comment: null,
                        type: 'open'
                    })
                )
            }

            Promise.all(requests)
                .then(() => {
                    this.$notify({
                        type: 'success',
                        title: 'Ok',
                        message: 'The order has been sent'
                    })
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: 'Error while order sending'
                    })
                })
                .finally(() => {
                    this.showModalOpenAfterClose = false
                    this.showModalClose = false

                    this.selectedTradeForClose = {
                        ticket: null,
                        symbol: null,
                        type: null,
                    }
                })
        },
        newOrderClick: function () {
            axios.post('/admin/web-orders', {
                account_id: this.selectedAccount.account_id,
                symbol: this.selectedSymbol,
                lot: this.newOrder.lots,
                type_open: this.newOrder.type,
                deviation: this.newOrder.deviation,
                magic: this.newOrder.magic,
                comment: this.newOrder.comment,
                stop_loss: (this.newOrder.stopLoss !== 0 && this.newOrder.type <= 1) ? this.newOrder.stopLoss : null,
                take_profit: (this.newOrder.takeProfit !== 0 && this.newOrder.type <= 1) ? this.newOrder.takeProfit : null,
                type: 'open'
            })
                .then(() => {
                    this.$notify({
                        type: 'success',
                        title: 'Ok',
                        message: 'The order has been sent'
                    })
                })
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: 'Error',
                        message: 'Error while order sending'
                    })
                })
                .finally(() => {
                    this.showModalOpen = false
                })
        },
        newOrderMagicChange: function () {
            this.newOrder.comment = `(${this.newOrder.magic})`
        },
    },
    mounted() {
        // If no account selected go to previous route
        if (!this.selectedAccount) {
            this.$router.go(-1)
            return
        }

        // Load charts settings
        this.$store.dispatch('admin/chartsSettings/loadState')

        // Cleanup TODO: delete on next release
        window.localStorage.removeItem(CANDLES_LIMIT_NAME)
        window.localStorage.removeItem(SELECTED_PERIOD)
        window.localStorage.removeItem(SELECTED_INDICATORS)

        // Reload symbols
        this.reloadSymbols()
        // Set symbols timer
        this.getSymbolsTimer = setInterval(this.reloadSymbols, 60 * 1000)

    },
    beforeDestroy() {
        clearInterval(this.getSymbolsTimer)
        clearInterval(this.getLastCandleTimer)
    },
}
</script>

<style scoped>
button {
    padding: 9px 15px;
}
.nav-link, button {
    font-size: 14px;
}

.period-selector >>> .el-radio-button__inner {
    font-size: 14px;
    padding: 9px;
}

table.dataTable thead th, table.dataTable thead td {
    padding: 5px;
}

table.dataTable tbody td {
    font-size: 12px;
    line-height: 10px;
    text-align: center;
}

table.dataTable th {
    font-size: 14px;
    line-height: 10px;
    text-align: center;
    font-weight: 600;
}

table.dataTable .text-left {
    text-align: left;
}
</style>
